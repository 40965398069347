<template>
  <div class="wait-for-game-link flex items-center justify-center">
    <div>{{ $t('common.waitForLink') }}</div>
  </div>
</template>

<script>
  export default {
    name: 'WaitForGameLink'
  };
</script>

<style scoped lang="scss">
  .wait-for-game-link {
    color: white;
    @include font(20px, 600);

    height: 100vh;
    width: 100vw;
    background: color(accent);
  }
</style>
