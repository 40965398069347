<template>
  <Button :disabled="$attrs.loading" :class="$attrs.class">
    <i v-if="$attrs.loading" class="pi pi-spin pi-spinner mx2"></i>
    <slot v-else />
  </Button>
</template>

<script>
  export default {
    name: 'BaseButtonWithSpinner'
  };
</script>

<style scoped>
  i {
    color: white;
  }
</style>
