
  import { defineComponent, onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';

  export default defineComponent({
    components: { BaseButtonWithSpinner, LanguageSelection },
    setup() {
      const { locale } = useI18n();

      const recaptcha = ref(null);
      const captchaVerified = ref(true);
      const siteKey = ref('6Ldr3VEeAAAAAB29o7tQx_7oMK4KLCmmvLYcRt2e');
      const { push, currentRoute } = useRouter();
      const store = useStore();

      const games = [
        {
          key: 'funteamclues',
          label: ['games.labels.individual', 'games.labels.rivalry', 'games.labels.fourToTen'],
          title: 'FunTeamClues',
          description: ['games.funteamclues.description.0', 'games.funteamclues.description.1'],
          new: true
        },
        {
          key: 'funicebreaker',
          label: ['games.labels.individual', 'games.labels.icebreaker', 'games.labels.fourToTen'],
          title: 'FunIceBreaker',
          description: ['games.funicebreaker.description.0', 'games.funicebreaker.description.1']
        },
        {
          key: 'funteambidding',
          label: ['games.labels.team', 'games.labels.rivalry', 'games.labels.fourToTwenty'],
          title: 'FunTeamBidding',
          description: [
            'games.funteambidding.description.0',
            'games.funteambidding.description.1',
            'games.funteambidding.description.2'
          ]
        },
        {
          key: 'funbusinessgame',
          label: ['games.labels.individual', 'games.labels.rivalry', 'games.labels.twoToEight'],
          title: 'FunBusinessGame',
          description: [
            'games.funbusinessgame.description.0',
            'games.funbusinessgame.description.1'
          ]
        }
      ];

      const verifyMethod = () => {
        captchaVerified.value = true;
        console.log('verifyMethod');
      };

      const expiredMethod = () => {
        captchaVerified.value = false;
        console.log('expiredMethod');
      };

      const renderMethod = () => {
        console.log('renderMethod');
      };

      const errorMethod = () => {
        console.log('errorMethod');
      };

      const goToCustomizeGame = async (game: string) => {
        push({ name: `${game}-new-game-without-register` });
        // await store.dispatch('sendAdminEmail', {
        //   message: `Sb clicked on PlayWithoutRegister game - ${game}`
        // });
      };

      onMounted(async () => {
        // @ts-ignore
        locale.value = currentRoute.value.query.language || 'en';
        // await store.dispatch('sendAdminEmail', {
        //   message: `Sb went to PlayWithoutRegister.vue`
        // });
      });

      return {
        siteKey,
        captchaVerified,
        games,
        recaptcha,
        verifyMethod,
        expiredMethod,
        goToCustomizeGame,
        renderMethod,
        errorMethod
      };
    }
  });
